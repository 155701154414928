import './App.css';
import React from 'react';
import AppRouter from './router/AppRouter';
import { ThemeProvider } from './theming/ThemeContext';

function App() {
  return (
    <ThemeProvider>
      <AppRouter />
    </ThemeProvider>
  );
}

export default App;
