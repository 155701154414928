import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    themeName: 'White'
}

const themeNameChange = createSlice({
    name: "themename",
    initialState,
    reducers: {
        themeNameChangingAction: (state = initialState, action) => {
            const themeNameChangingState = {
                themeName: action.payload,
            };
            return themeNameChangingState;
        }
    },
})

export const { themeNameChangingAction } = themeNameChange.actions;
export default themeNameChange.reducer;